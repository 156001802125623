<template>
	<div id="dorm">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">宿舍管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content" style="padding: 0 15px;">
				<div style="padding: 15px 0;">请按照指引逐步完善需要生成的报表</div>
				<div class="u-f-item">
					<div style="margin-right: 5px;">一、选择报表统计的时间范围</div>
					<el-date-picker size="small"
					  v-model="times"
					  value-format="yyyy-MM-dd" format="yyyy-MM-dd"
					  type="daterange"
					   @change="timeChange"
					  range-separator="至"
					  start-placeholder="开始日期"
					  end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="u-f-item" style="margin-top: 15px;">
					<div style="margin-right: 5px;">二、选择报表统计方式</div>
					<el-select v-model="value" placeholder="请选择"  size="small" @change="selectChange">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
				</div>
				<div style="padding: 15px 15px 0;" v-if="value!='3'">
					<el-radio-group v-model="grade_id">
					    <el-radio :label="item.id" v-for="(item,index) in gradeList" :key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</div>
				<div style="padding: 15px 15px 0;" v-if="value=='3'">
					<el-radio-group v-model="dormitory_id">
					    <el-radio :label="item.id" v-for="(item,index) in placeList" :key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</div>
				<div class="u-f-item" style="margin-top: 15px;" v-if="value!='1'">
					<div style="margin-right: 5px;">三、是否需要详细记录</div>
					<el-select v-model="value2" placeholder="请选择"  size="small" @change="selectChange2">
					    <el-option
					      v-for="item in options2"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
				</div>
				<el-button type="primary" @click="daochu" size="small" style="margin-top: 15px;">生成报表</el-button>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			teacherMeun: [
				{ name: '常规录入' },
				{ name: '宿管安排' },
				{ name: '学生安排' },
				{ name: '导出报表' },
				{ name: '预警提示' },
				{ name: '批量嘉奖' },
				{ name: '学生奖惩' },
				{ name: '奖惩录入' }
			],
			teacherIndex: 3,
			options: [{
			  value: '1',
			  label: '以个人为单位'
			}, {
			  value: '2',
			  label: '以班级为单位'
			}, {
			  value: '3',
			  label: '以宿舍为单位'
			}],
			options2: [{
			  value: 1,
			  label: '是'
			}, {
			  value: 0,
			  label: '否'
			}],
			value:'1',
			value2:0,
			times:'',
			grade_id:'',
			gradeList:[],
			placeList:[],
			dormitory_id:'',
			start_date:'',
			end_date:''
		};
	},
	mounted() {
		this.gradeData()
		this.getPlaceList()
	},
	methods: {
		// 获取宿舍楼列表
		getPlaceList() {
			this.$api.setting.dormitoryList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					this.placeList = data;
					this.dormitory_id = data[0].id;
				}
			});
		},
		// 年级列表
		gradeData(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data.rows;
					this.gradeList = data;
					this.grade_id = data[0].id;
				}
			})
		},
		twacherTap(index){
			this.teacherIndex = index;
			this.teacherIndex = index;
			if(this.teacherIndex==0){
				this.$router.push('/dorm')
			}else if(this.teacherIndex==1){
				this.$router.push('/dorm1')
			}else if(this.teacherIndex==2){
				this.$router.push('/dorm2')
			}else if(this.teacherIndex==3){
				this.$router.push('/daochu')
			}else if(this.teacherIndex==4){
				this.$router.push('/yujing')
			}else if(this.teacherIndex==5){
				this.$router.push('/plliang')
			}else if(this.teacherIndex==6){
				this.$router.push('/xuesheng')
			}else if(this.teacherIndex==7){
				this.$router.push('/dormAdd')
			}
		},
		timeChange(e){
			console.log(e)
			this.start_date = e[0];
			this.end_date = e[1];
		},
		selectChange(e){
			console.log(e)
		},
		selectChange2(e){
			
		},
		daochu(){
			if(this.value=='1'){
				this.gerenDaochu()
			}else if(this.value=='2'){
				this.banjiDaochu()
			}else if(this.value=='3'){
				this.susheDaochu()
			}
		},
		gerenDaochu(){
			let data = {
				grade_id:this.grade_id,
				start_date:this.start_date,
				end_date:this.end_date
			}
			this.$api.setting.exports8(data).then(res=>{
				if(res.data.code == 1){
					this.$message('导出成功');
					this.shuxin()
					location.href = res.data.data;
				}
			})
		},
		banjiDaochu(){
			let data = {
				grade_id:this.grade_id,
				start_date:this.start_date,
				end_date:this.end_date,
				type:this.value2
			}
			this.$api.setting.exports9(data).then(res=>{
				if(res.data.code == 1){
					this.$message('导出成功');
					this.shuxin()
					location.href = res.data.data;
				}
			})
		},
		susheDaochu(){
			let data = {
				dormitory_id:this.dormitory_id,
				start_date:this.start_date,
				end_date:this.end_date,
				type:this.value2
			}
			this.$api.setting.exports12(data).then(res=>{
				if(res.data.code == 1){
					this.$message('导出成功');
					this.shuxin()
					location.href = res.data.data;
				}
			})
		},
		shuxin(){
			this.start_date= '';
			this.end_date = '';
			this.value2 = 0;
			this.value = '1';
			this.times = '';
		}
	}
};
</script>

<style lang="scss">
#dorm {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 68vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan,.suguansss{
			padding: 25px 50px;
			height: 70vh;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
